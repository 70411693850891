import React from "react"
import { Link } from "react-router-dom";
import logo from "../../images/logo-paradigma.svg"

const Footer = () => {
  // const path = window.location.pathname;

  return <>
    <div className="section_footer">
      <div className="page-padding">
        <div className="container-large padding-vertical padding-xlarge">
          <div className="footer-wrapper">
            <Link to="/" className="footer-wrapper-logo w-inline-block">
              <img src={logo} width="64" height="64" loading="lazy" alt="" />
            </Link>
            <div className="footer-links">
              <a 
                href="https://paradigma.docsend.com/view/ku5u6ak" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-size-medium text-color-white"
              >
                Termos de Uso
              </a>
              <a 
                href="https://paradigma.docsend.com/view/82h48x9" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-size-medium text-color-white margin-horizontal margin-medium"
              >
                Privacidade
              </a>
              <a
                href="https://discord.com/invite/wj25gPgzym" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-size-medium text-color-white"
              >
                Contato
              </a>
            </div>

            <div className="footer-addresses">
              <div 
                className="text-size-medium text-color-white"
              >
                Rua Coronel José Eusébio, 95 São Paulo, Brasil 34.380.409/0001-00
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default Footer;


