import React from "react";

// import { intervalToDuration } from "date-fns";

import iconBrazil from "../../../images/flag-brazil.png";

const ActionBar = ({ isTrack }) => {
  // const [duration, setDuration] = useState({});
  // const path = window.location.pathname;

  // useEffect(() => {
  //   setInterval(() => {
  //     setDuration(
  //       intervalToDuration({
  //         start: new Date(),
  //         end: new Date("2023-12-01T23:59:59"),
  //       })
  //     );
  //   }, 1000);
  // }, []);

  return (
    <>
      <div
        className={
          isTrack
            ? "stickbar-section stickbar-section-tracks"
            : "stickbar-section"
        }
      >
        <div className="page-padding">
          <div className="text-size-medium text-color-white">
            <img
              src={iconBrazil}
              width="13"
              height="13"
              alt="Bandeira Brasil"
              style={{ marginRight: "10px" }}
            />
            {/* {isTrack ? ( */}
            <span>
              O único conteúdo brasileiro consumido pelos maiores participantes
              globais do mercado.
            </span>
            {/* ) : ( */}
            {/* <span> */}
              {/* <span style={{ fontWeight: "bold" }}>BLACK FRIDAY ESTENDIDA</span>: 2 anos por */}
              {/* 1 na assinatura Paradigma Pro | ⏰ */}
              {/* <span style={{ fontWeight: "bold" }}> CYBER WEEK </span>| ⏰ */}
              {/* <span style={{ color: "#3de8a0" }}>{` ${duration.days || "0"}d ${ */}
                {/* duration.hours || "0" */}
              {/* }h ${duration.minutes || "0"}m ${ */}
                {/* duration.seconds || "00" */}
              {/* }s`}</span> */}
            {/* </span> */}
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionBar;
